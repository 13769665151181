<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M19.75 10c0 5.385-4.365 9.75-9.75 9.75S.25 15.385.25 10 4.615.25 10 .25s9.75 4.365 9.75 9.75zm-1.5 0a8.25 8.25 0 1 0-16.5 0 8.25 8.25 0 0 0 16.5 0z"
    />
    <path d="M10.818 6.727a.817.817 0 1 1-1.635.002.817.817 0 0 1 1.635-.002" />
    <path fill-rule="nonzero" d="M9.25 10v4.09a.75.75 0 1 0 1.5 0V10a.75.75 0 1 0-1.5 0z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
